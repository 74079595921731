@import "../modal/modal.scss";

.GridContenedor {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: center;
}

.gridItem {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  height: 200px;
  background-color: $color_2;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  justify-items: center;

  > h3 {
    font-family: $fuente-titulos;
  }

  > span {
    font-family: $fuente-textos;
  }

  &.dark {
    color: white;
    background-color: $colorDark_3;
  }
}

.Item1 {
  //Conocimientos
  overflow-y: auto; //por si el <p> sale del div definido de 200px height
  > p > a {
    color: black;
    &.dark {
      color: white;
    }
  }
}

.Item2 {
  // Concepción, Chile
  background-image: url("./assets/img/concepcion.jpg");
  background-size: cover;
  text-decoration: none;
  > span {
    color: black;
    font-weight: bold;
    font-size: 40px;
  }
  &.dark {
    color: black;
    filter: brightness(70%);
  }
}

.Item3 {
  //Github Link
  background-image: url("./assets/img/devkid.gif");
  background-position: center;
  color: white;
  font-weight: bold;
  font-size: 40px;
  text-align: end;
  text-decoration: none;
}

.Item4 {
  //Correo Electronico
  background-image: url("./assets/img/email.gif");
  background-position: center;
  display: flex;
  justify-content: center;

  & > span {
    margin-top: -5px;
    font-weight: bold;
    text-decoration: none;
    font-size: 30px;
    color: white;
  }
}


.Item5 {
  // nivel de ingles
  background-color: $coloringles;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  &.dark {
    color: black;
    background-color: $coloringlesDark;
  }
}

@media only screen and (min-width: 1200px) {
  .GridContenedor {
    display: grid;
    width: 1200px;
    grid-template-columns: repeat(auto-fit, 1fr);
  }

  .Item1 {
    //Conocimientos
    overflow-y: hidden;
    padding: 25px;
    grid-area: 1 / 1 / 2 / 4;
  }

  .Item3 {
    //Github Link
    grid-area: 2 / 1 / 2 / 3;
  }
}

@media (min-width: 580px) and (max-width: 1200px) {
  .Item1 {
    //Conocimientos
    overflow-y: hidden;
    min-width: 280px;
    padding: 25px;
    grid-area: 1 / 1 / 2 / 3;
  }
}
