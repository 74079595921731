.navbar {
  background-color: #EBE3D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.navbar.dark {
  color: white;
  background-color: #2e3c4c;
}

.navbar-toggler {
  background-color: #B0A695;
}
.navbar-toggler.dark {
  background-color: #789dca;
}

.nav-link {
  font-size: 20px;
  font-weight: bold;
}
.nav-link > span {
  display: block;
}
.nav-link.dark {
  color: white;
}
.nav-link.dark.active {
  color: white;
  text-decoration: underline #789dca 0.15rem;
  text-underline-offset: 0.5rem;
}
.nav-link.active {
  text-decoration: underline #776B5D 0.15rem;
  text-underline-offset: 0.5rem;
}

h1 {
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 35px;
  margin-right: 50px;
}

.botones-tema {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media only screen and (min-width: 991px) {
  .botones-tema {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .nav-link {
    font-size: 20px;
    font-weight: bold;
  }
  .nav-link.dark {
    color: white;
  }
  .nav-link:hover {
    background-color: #B0A695;
    border-radius: 5px;
  }
  .nav-link:hover.dark {
    background-color: #4b627e;
    border-radius: 5px;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: #EBE3D5;
  padding: 15px;
}
.footer.dark {
  color: white;
  background-color: #2e3c4c;
}
.footer > img {
  width: 150px;
  height: 150px;
  padding: 10px;
  margin-bottom: 30px;
}
.footer > p > a {
  color: black;
  font-weight: bold;
}
.footer > p > a.dark {
  color: white;
}

@media only screen and (min-width: 991px) {
  .footer {
    padding: 0 250px 0 250px;
  }
}
.BentoPrincipal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #EBE3D5;
  margin: 50px 10px 50px 10px;
  padding: 20px;
  border-radius: 10px;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
}
.BentoPrincipal.dark {
  color: white;
  background-color: #2e3c4c;
}

.FotoPerfil > input {
  margin: 10px;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
}
.FotoPerfil > p {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
}
.FotoPerfil > p > svg {
  margin-right: 5px;
}

.BentoPrincipalSaludo {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.BentoPrincipalSaludo > h2 {
  font-family: "Ubuntu Condensed", sans-serif;
  text-align: center;
}
.BentoPrincipalSaludo .dark {
  color: white;
}

.BentoPrincipalLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.BentoPrincipalLinks > a {
  background-color: #be2af2;
  color: white;
}
.BentoPrincipalLinks > a:hover {
  background-color: #8820ae;
  color: white;
}

@media only screen and (min-width: 991px) {
  .BentoPrincipal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 900px;
    background-color: #EBE3D5;
    margin: 50px 10px 50px 10px;
    padding: 20px;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  }
  .BentoPrincipal.dark {
    color: white;
    background-color: #2e3c4c;
  }
  .BentoPrincipalSaludo {
    display: flex;
    flex-direction: column;
    text-align: justify;
    margin-left: 30px;
  }
  .BentoPrincipalSaludo > h2 {
    text-align: start;
  }
  .BentoPrincipalSaludo .dark {
    color: white;
  }
}
.modal-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(72, 72, 72, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: flex;
  margin: 10px;
  margin-top: 50px;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  background-color: #EBE3D5;
  border-radius: 10px;
  padding: 15px;
}
.modal-box > h3 {
  font-family: "Ubuntu Condensed", sans-serif;
}
.modal-box > label,
.modal-box input {
  font-family: "Roboto Flex", sans-serif;
}
.modal-box > .btn {
  align-self: flex-end;
}
.modal-box.dark {
  background-color: #2e3c4c;
  color: white;
}

.formulario {
  width: 100%;
}
.formulario > .btn {
  margin: 10px;
  width: 150px;
  float: right;
}
.formulario.dark > textarea,
.formulario.dark input {
  color: rgb(255, 255, 255);
  background-color: #15202b;
}

@media only screen and (min-width: 991px) {
  .modal-background {
    left: 0;
    align-items: flex-start;
  }
  .modal-box {
    display: flex;
    margin: 10px;
    margin-top: 300px;
    width: 500px;
  }
}
.GridContenedor {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: center;
}

.gridItem {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  height: 200px;
  background-color: #EBE3D5;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  justify-items: center;
}
.gridItem > h3 {
  font-family: "Ubuntu Condensed", sans-serif;
}
.gridItem > span {
  font-family: "Roboto Flex", sans-serif;
}
.gridItem.dark {
  color: white;
  background-color: #2e3c4c;
}

.Item1 {
  overflow-y: auto;
}
.Item1 > p > a {
  color: black;
}
.Item1 > p > a.dark {
  color: white;
}

.Item2 {
  background-image: url("./assets/img/concepcion.jpg");
  background-size: cover;
  text-decoration: none;
}
.Item2 > span {
  color: black;
  font-weight: bold;
  font-size: 40px;
}
.Item2.dark {
  color: black;
  filter: brightness(70%);
}

.Item3 {
  background-image: url("./assets/img/devkid.gif");
  background-position: center;
  color: white;
  font-weight: bold;
  font-size: 40px;
  text-align: end;
  text-decoration: none;
}

.Item4 {
  background-image: url("./assets/img/email.gif");
  background-position: center;
  display: flex;
  justify-content: center;
}
.Item4 > span {
  margin-top: -5px;
  font-weight: bold;
  text-decoration: none;
  font-size: 30px;
  color: white;
}

.Item5 {
  background-color: rgb(231, 231, 35);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}
.Item5.dark {
  color: black;
  background-color: rgb(184, 184, 5);
}

@media only screen and (min-width: 1200px) {
  .GridContenedor {
    display: grid;
    width: 1200px;
    grid-template-columns: repeat(auto-fit, 1fr);
  }
  .Item1 {
    overflow-y: hidden;
    padding: 25px;
    grid-area: 1/1/2/4;
  }
  .Item3 {
    grid-area: 2/1/2/3;
  }
}
@media (min-width: 580px) and (max-width: 1200px) {
  .Item1 {
    overflow-y: hidden;
    min-width: 280px;
    padding: 25px;
    grid-area: 1/1/2/3;
  }
}
.inicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f3f0;
  width: 100%;
  min-height: 90vh;
  padding-bottom: 40px;
}
.inicio.dark {
  background-color: #4b627e;
}

.contenedor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cardDiv {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.proyectoTitulo {
  font-size: 25px;
  margin: 20px;
  opacity: 0.4;
  user-select: none;
}
.proyectoTitulo.dark {
  color: white;
  opacity: 0.7;
}

.card {
  margin: 10px;
  max-width: 380px;
  background-color: #EBE3D5;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  overflow: hidden;
}
.card img {
  height: 225px;
  width: 400px;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
.card.dark {
  background-color: #2e3c4c;
  color: white;
}
.card.dark .overlay {
  background-color: #15202b;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 225px;
  color: white;
  font-size: 30px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.HandClick {
  width: 50%;
  height: 50%;
  margin: auto;
}

.card .overlay {
  background-color: #776B5D;
  opacity: 0.8;
  transform: translateX(-100%);
  -webkit-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

.card .box:hover .overlay {
  transform: translateX(0);
}

.card-body {
  height: 200px;
}

.tecnologias {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  text-align: center;
  font-size: 25px;
  z-index: -1;
}

.proyectos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  margin-top: 50px;
  min-height: 90vh;
  margin-bottom: 50px;
  max-width: 1350px;
}
.proyectos > h2 {
  font-size: 50px;
  font-family: "Ubuntu Condensed", sans-serif;
}
.proyectos.dark > h2 {
  color: white;
}

@media only screen and (min-width: 991px) {
  .proyectos {
    justify-content: center;
    margin-top: 0;
  }
}
.btn {
  background-color: #B0A695;
  font-family: "Roboto Flex", sans-serif;
  color: black;
}
.btn:hover {
  background-color: #776B5D;
  color: white;
}
.btn.dark {
  background-color: #4b627e;
  color: white;
}
.btn.dark:hover {
  background-color: #789dca;
  color: black;
}

:root {
  background-color: #f6f3f0;
}
:root.dark {
  background-color: #4b627e;
}

.App {
  min-height: 100vh;
  background-color: #f6f3f0;
}
.App.dark {
  background-color: #4b627e;
}

