@import "./bentoprincipal.scss";
@import "./bentogrid.scss";

.inicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color_1;
  width: 100%;
  min-height: 90vh;
  padding-bottom: 40px;
  &.dark {
    background-color: $colorDark_2;
  }
}
