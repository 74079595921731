@import "../../variables";

.card {
  margin: 10px;
  max-width: 380px;
  background-color: $color_2;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  overflow: hidden;
  & img {
    height: 225px;
    width: 400px;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  &.dark {
    background-color: $colorDark_3;
    color: white;

    .overlay{
      background-color: $colorDark_4;
    }
  }
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 225px;
  color: white;
  font-size: 30px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.HandClick{
  width: 50%;
  height: 50%;
  margin: auto;
}

.card .overlay {
  background-color: $color_4;
  opacity: 0.8;
  transform: translateX(-100%);
  -webkit-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

.card .box:hover .overlay {
  transform: translateX(0);
}


.card-body {
  height: 200px;
}

.tecnologias {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  text-align: center;
  font-size: 25px;
  z-index: -1;
}
