.BentoPrincipal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $color_2;
  margin: 50px 10px 50px 10px;
  padding: 20px;
  border-radius: 10px;
  filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  &.dark {
    color: white;
    background-color: $colorDark_3;
  }
}

.FotoPerfil {
  > input {
    margin: 10px;
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
  }
  > p {
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
  }
  > p > svg {
    margin-right: 5px;
  }
}

.BentoPrincipalSaludo {
  display: flex;
  flex-direction: column;
  text-align: justify;
  > h2{
    font-family: $fuente-titulos;
    text-align: center;
  }
  & .dark {
    color: white;
  }
}

.BentoPrincipalLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  > a {
    background-color: #be2af2;
    color: white;
    &:hover{
      background-color: #8820ae;
      color: white;
    }
  }


}

@media only screen and (min-width: 991px) {
  .BentoPrincipal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 900px;
    background-color: $color_2;
    margin: 50px 10px 50px 10px;
    padding: 20px;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.25));
    &.dark {
      color: white;
      background-color: $colorDark_3;
    }
  }

  .BentoPrincipalSaludo {
    display: flex;
    flex-direction: column;
    text-align: justify;
    margin-left: 30px;
    > h2{
      text-align: start;
    }
    & .dark {
      color: white;
    }
  }
}
