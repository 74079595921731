.contenedor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cardDiv {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.proyectoTitulo {
  font-size: 25px;
  margin: 20px;
  opacity: 0.4;
  user-select: none;
  &.dark {
    color: white;
    opacity: .7;
  }
}
