@import "../../variables";

.navbar {
  background-color: $color_2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &.dark {
    color: white;
    background-color: $colorDark_3;
  }
}

.navbar-toggler {
  background-color: $color_3;
  &.dark {
    background-color: $colorDark_1;
  }
}

.nav-link {
  font-size: 20px;
  font-weight: bold;
  > span {
    display: block;
  }
  &.dark {
    color: white;
    &.active {
      color: white;
      text-decoration: underline $colorDark_1 0.15rem;
      text-underline-offset: 0.5rem;
    }
  }

  &.active {
    text-decoration: underline $color_4 0.15rem;
    text-underline-offset: 0.5rem;
  }
}

h1 {
  font-family: $fuente-titulos;
  font-size: 35px;
  margin-right: 50px;
}

.botones-tema {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media only screen and (min-width: 991px) {
  //ajustes para desktop version
  .botones-tema {
    //botones aliniados a la derecha si el navbar no tiene la hamburguesa
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .nav-link {
    font-size: 20px;
    font-weight: bold;
    &.dark {
      color: white;
    }
    &:hover {
      background-color: $color_3;
      border-radius: 5px;
      &.dark {
        background-color: $colorDark_2;
        border-radius: 5px;
      }
    }
  }
}
