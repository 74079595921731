$fuente-textos: "Roboto Flex", sans-serif;
$fuente-titulos: 'Ubuntu Condensed', sans-serif;


//Paleta de colores
$color_1: #f6f3f0;
$color_2: #EBE3D5;
$color_3: #B0A695;
$color_4: #776B5D;

//paleta de colores modo oscuro
$colorDark_1: #789dca;
$colorDark_2: #4b627e;
$colorDark_3: #2e3c4c;
$colorDark_4: #15202b;

//color Bento Ingles
$coloringles : rgb(231, 231, 35);
$coloringlesDark : rgb(184, 184, 5);