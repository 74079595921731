@import '../../variables.scss';

.modal-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(72, 72, 72, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: flex;
  margin: 10px;
  margin-top: 50px;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  background-color: $color_2;
  border-radius: 10px;
  padding: 15px;
  > h3 {
    font-family: $fuente-titulos;
  }

  > label,
  input{
    font-family: $fuente-textos;
  }
  > .btn {
    align-self: flex-end;
  }
  &.dark {
    background-color: $colorDark_3;
    color: white;

  }
}

.formulario {
  width: 100%;
  > .btn {
    margin: 10px;
    width: 150px;
    float: right;
  }
  &.dark {
    > textarea,
    input{
      color: rgb(255, 255, 255);
      background-color: $colorDark_4;
    }
  }
}

@media only screen and (min-width: 991px) {
  .modal-background {
    left: 0;
    align-items: flex-start;
  }

  .modal-box {
    display: flex;
    margin: 10px;
    margin-top: 300px;
    width: 500px;
  }
}
