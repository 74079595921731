@import './variables';

.btn {
    background-color: $color_3;
    font-family: $fuente-textos;
    color: black;
    &:hover{
        background-color: $color_4;
        color: white;
    }
    &.dark {
      background-color: $colorDark_2;
      color: white;
      &:hover{
        background-color: $colorDark_1;
        color: black;
      }
    }
  }

