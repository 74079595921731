.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: $color_2;
  padding: 15px;
  &.dark {
    color: white;
    background-color: $colorDark_3;
  }

  > img {
    width: 150px;
    height: 150px;
    padding: 10px;
    margin-bottom: 30px;
  }

  > p > a {
    color: black;
    font-weight: bold;
    &.dark {
        color: white;
      }
  }
}

@media only screen and (min-width: 991px) {

    .footer{ //hace un padding extra para cuando no esté la versión mobile
        padding: 0 250px 0 250px;
    }
}