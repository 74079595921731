@import "variables";
@import "./componentes/navbar/navbar.scss";
@import "./componentes/footer/footer.scss";
@import "./componentes/inicio/inicio.scss";
@import "./componentes/proyectos/proyectos.scss";
@import "./buttons.scss";
:root {
    background-color: $color_1;
  &.dark {
    background-color: $colorDark_2;
  }
}

.App{
  min-height: 100vh;
  background-color: $color_1;
  &.dark {
    background-color: $colorDark_2;
  }
}