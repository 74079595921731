@import "./cards.scss";
@import "./card.scss";

.proyectos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    margin-top: 50px;
    min-height: 90vh;
    margin-bottom: 50px;
    max-width: 1350px;
    >h2{
        font-size: 50px;
        font-family: $fuente-titulos;
    }

    &.dark{
        >h2{
            color: white;
        }
    }
}

@media only screen and (min-width: 991px) {

    .proyectos{
        justify-content: center;
        margin-top: 0;
    }
}


